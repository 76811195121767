import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Layout } from '../components';
import Content from '../components/Content';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';


class Impressum extends Component {
  render() {
    const {
      data: { impressum }
    } = this.props;

    return (
      <Layout>
        <Section featured>
          <SectionTitle centered>{impressum.data.title.text}</SectionTitle>
        </Section>
        <Section>
          <Content dangerouslySetInnerHTML={{ __html: impressum.data.content.html }} />
        </Section>
      </Layout>
    );
  }
}

export default Impressum;

Impressum.propTypes = {
  data: PropTypes.shape({
    impressum: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string.isRequired
        }),
        content: PropTypes.shape({
          html: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired
        })
      })
    })
  }).isRequired
};

export const pageQuery = graphql`
  query ImpressumQuery {
    impressum: prismicImpressum {
      data {
        title {
          text
        }
        content {
          html
          text
        }
      }
    }
  }
`;
